
import { Component, Prop, Ref } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import Api from '@/models/Api'
import WebMessage from '../../models/WebMessage'

@Component({
  components: {
    Widget,
    FormInput,
  },
})
export default class ZipCodeLookup extends ViewModel {
  public zipcodes: string = ''

  public range: string = ''

  public result: any = null

  public result_merged: string[] = []

  public scope_view = 'group'

  public scope_view_options = [
    { text: 'Group By Zipcode', value: 'group' },
    { text: 'Merge All', value: 'merge' },
  ]

  public run(download: boolean = false) {
    let zipcodes = this.zipcodes.split(/[\n,;]/)
    const api = new Api()
    this.loading = true
    api
      .post('tools/zipcode-lookup', {
        zipcodes,
        range: this.range,
        download,
      })
      .then(response => {
        this.loading = false
        if (typeof response.data.result.report == 'string') {
          WebMessage.success('Your download should start shortly.')
        } else {
          this.result = response.data.result.report
          let merged: string[] = []
          for (const key in this.result) {
            if (this.result.hasOwnProperty(key)) {
              this.result_merged += this.result[key].join(', ')
              merged = merged.concat(this.result[key])
            }
          }

          // remove duplicates
          this.result_merged = [...new Set(merged)]
        }
      })
      .catch(() => {
        this.loading = false
        WebMessage.error('There was an error processing your request, please try again later')
      })
  }
}
